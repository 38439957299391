<template>
  <div class="comments-block" :class="`comments-${comment._id}`">
    <div class="comments-block-list">
      <CommentsViewCard v-for="(reply, index) in replies" :key="index" :item="reply" />
    </div>
    <div class="comments-block-options" v-if="hasMore">
      <div v-if="loading"><iconic name="gspinner" /></div>
      <div v-else>
        <a class="more-button" @click="getCommentReplies">
          <div class="more-button-left"></div>
          <div class="more-button-right">
            <span>{{ $locale.words["see"] + " (" + leftMore + ") " }}</span>
            <span lowercase>{{ $locale.words["replies"] + " " + $locale.words["more"] }}</span>
          </div>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["comment"],
  data: function() {
    return {
      page: 1,
      limit: 12,
      loading: false,
      replies: this.comment.replies,
    };
  },
  components: {
    CommentsViewCard: () => import("./CommentsViewCard.vue"),
    ViewMoreBtn: () => import("../widgets/ViewMoreBtn.vue"),
  },
  methods: {
    getCommentReplies: async function({ page = 1, limit = 12 } = {}) {
      this.loading = true;
      try {
        const container = document.querySelector(`.comments-${this.comment._id}`);
        let query = `post=${this.$route.query.comments}&reply=${this.comment._id}&page=${page}&limit=${this.limit}`;
        const req = await this.$api.get(`${process.env.VUE_APP_API_HOST}comments?${query}`);
        if (req.data.comments) {
          this.replies.push(...req.data.comments);
          await this.sleep(100);
          container.scrollIntoView({ behavior: "smooth" });
        }
      } catch (error) {
        this.ialert.go({ title: "Error", message: error });
      }
      this.loading = false;
    },
  },
  computed: {
    hasMore: function() {
      return this.replies.length < this.comment.totalReplies;
    },
    leftMore: function() {
      return this.comment.totalReplies - this.replies.length;
    },
  },
};
</script>

<style lang="scss" scoped>
.comments-block {
  position: relative;
  &-options {
    padding: 0 0 $mpadding/2 $mpadding * 4;
    .more-button {
      @include Flex(inherit, inherit, center);
      cursor: pointer;
      opacity: 0.7;
      &:hover {
        color: $primary_color;
        opacity: 1;
      }
      &-left {
        width: 24px;
        min-width: 24px;
        height: 1px;
        background: #000;
        margin: 0 $mpadding 0 0;
      }
    }
  }
}
*[deep="0"] {
  .comments-block {
    &-options {
      padding: 0 0 $mpadding/2 $mpadding * 5;
    }
  }
}
</style>
